//Images
import cassettePlayer from "../img/casettePage.jpeg";
import portFolio from "../img/portfolio.jpeg";

import styled from "styled-components";
import { Link } from "react-router-dom";

//Animation import
import { motion } from "framer-motion";
import {
	pageAnimation,
	fade,
	photoAnimation,
	lineAnimation,
	slider,
	sliderContainer,
} from "../Animation";

import { useScroll } from "../components/useScroll";

const OurWork = () => {
	const [element1, controls1] = useScroll();
	const [element2, controls2] = useScroll();
	// const [element3, controls3] = useScroll();

	return (
		<Work
			exit="exit"
			variants={pageAnimation}
			initial="hidden"
			animate="show"
		>
			<motion.div variants={sliderContainer}>
				<Frame1 variants={slider}></Frame1>
				<Frame2 variants={slider}></Frame2>
				<Frame3 variants={slider}></Frame3>
				<Frame4 variants={slider}></Frame4>
			</motion.div>
			<PortFolioItem
				ref={element1}
				variants={fade}
				animate={controls1}
				initial="hidden"
			>
				<motion.h1 variants={fade}>Online Cassette Player</motion.h1>
				<motion.div
					variants={lineAnimation}
					className="line"
				></motion.div>
				<Link to="/work/cassette">
					<Hide>
						<motion.img
							variants={photoAnimation}
							src={cassettePlayer}
							alt="cassette player"
						/>
					</Hide>
				</Link>
			</PortFolioItem>
			<PortFolioItem
				ref={element2}
				variants={fade}
				animate={controls2}
				initial="hidden"
			>
				<motion.h1 variants={fade}>Portfolio site</motion.h1>
				<motion.div
					variants={lineAnimation}
					className="line"
				></motion.div>
				<Link to="/work/portFolio">
					<Hide>
						<motion.img
							variants={photoAnimation}
							src={portFolio}
							alt="racer"
						/>
					</Hide>
				</Link>
			</PortFolioItem>
			{/*<PortFolioItem*/}
			{/*	ref={element3}*/}
			{/*	variants={fade}*/}
			{/*	animate={controls3}*/}
			{/*	initial="hidden"*/}
			{/*>*/}
			{/*	<motion.h1 variant={fade}>Good times</motion.h1>*/}
			{/*	<motion.div*/}
			{/*		variants={lineAnimation}*/}
			{/*		className="line"*/}
			{/*	></motion.div>*/}
			{/*	<Link to="/work/good-times">*/}
			{/*		<Hide>*/}
			{/*			<motion.img*/}
			{/*				variants={photoAnimation}*/}
			{/*				src={goodtimes}*/}
			{/*				alt="good-times"*/}
			{/*			/>*/}
			{/*		</Hide>*/}
			{/*	</Link>*/}
			{/*</PortFolioItem>*/}
		</Work>
	);
};

const Work = styled(motion.div)`
	min-height: 100vh;
	overflow: hidden;
	padding: 5rem 10rem;
	@media (max-width: 900px) {
		padding: 2rem 2rem;
	}

	h2 {
		padding: 1rem 0;
	}
`;

const PortFolioItem = styled(motion.div)`
	padding-bottom: 10rem;

	h1 {
		color: white;
	}

	.line {
		height: 0.5rem;
		background: #23d997;
		margin-bottom: 3rem;
	}

	img {
		width: 100%;
		height: 70vh;
		object-fit: cover;
	}
`;

const Hide = styled.div`
	overflow: hidden;
`;

//Frame Animation
const Frame1 = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 10%;
	width: 100%;
	height: 100vh;
	background: #fffebf;
	z-index: 2;
`;
const Frame2 = styled(Frame1)`
	background: #ff8efb;
`;
const Frame3 = styled(Frame1)`
	background: #8ed2ff;
`;
const Frame4 = styled(Frame1)`
	background: #8effa0;
`;

export default OurWork;
