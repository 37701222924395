import clock from "../img/clock.svg";
import happy from "../img/happy-svgrepo-com.svg";
import money from "../img/money.svg";
import teamwork from "../img/teamwork.svg";
import home2 from "../img/ikke1.png";

//Styles
import { About, Description, Image } from "../style";
import styled from "styled-components";

//Scroll
import { useScroll } from "./useScroll";
import { fade } from "../Animation";

const ServicesSection = () => {
	const [element, controls] = useScroll();

	return (
		<Services
			variants={fade}
			animate={controls}
			initial="hidden"
			ref={element}
		>
			<Image>
				<img src={home2} alt="blabla" />
			</Image>
			<CardContainer>
				<h2>
					<span>Waarom</span> Silver Spirit?
				</h2>

				<Cards>
					<Card>
						<div className="icon">
							<img alt="clock-icon" src={clock} />
							<h3>Ervaring</h3>
						</div>
						<p>
							Dankzij veel praktische ervaringen weet Silver
							Spirit precies wat goed is voor uw bedrijf. Ik
							specialiseer me voornamelijk in front-end
							oplossingen, waarbij ook een goede back-end niet
							overgeslagen wordt.
						</p>
					</Card>
					<Card>
						<div className="icon">
							<img alt="teamwork-icon" src={teamwork} />
							<h3>Persoonlijk</h3>
						</div>
						<p>
							Vanwege onze kleinschaligheid is er altijd direct
							contact met de eigenaar.
						</p>
					</Card>
					<Card>
						<div className="icon">
							<img alt="money-icon" src={money} />
							<h3>Betaalbaar</h3>
						</div>
						<p>
							Altijd een passend tarief voor uw idee. Deze kan
							betaald worden per uur, of per project zodat u niet
							voor verassingen komt te staan.
						</p>
					</Card>
					<Card>
						<div className="icon">
							<img alt="diaphragm-icon" src={happy} />
							<h3>Gegarandeerd success</h3>
						</div>
						<p>
							Silver Spirit stopt niet tot u volledig tevreden
							bent met het eindproduct. Hierbij maakt het niet uit
							of dit voor, tijdens of na de oplevering is. Silver
							Spirit is er altijd voor u!
						</p>
					</Card>
				</Cards>
			</CardContainer>
		</Services>
	);
};

const Services = styled(About)`
	padding: 5rem 0 5rem 10rem;

	h2 {
		font-size: 3rem;
		padding-bottom: 5rem;
	}

	p {
		width: 90%;
		padding: 2rem 0 4rem 0;
	}

	@media (max-width: 900px) {
		display: flex;
		flex-direction: column-reverse;
		padding: 2rem;
		text-align: center;

		p {
			padding: 2rem 0;
		}
	}
`;

const Cards = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Card = styled.div`
	flex-basis: 50%;

	p {
		font-size: 1.2rem;
	}

	.icon {
		display: flex;
		align-items: center;

		h3 {
			margin-left: 1rem;
			background-color: white;
			color: black;
			padding: 1rem;
		}
	}

	@media (max-width: 900px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-basis: 100%;
		justify-content: center;
		margin-top: 1rem;

		.icon {
			display: flex;
			align-items: center;

			h3 {
				margin-left: 2rem;
				background-color: white;
				color: black;
				padding: 1.2rem;
				font-size: 2rem;
			}
		}

		p {
			font-size: 1.6rem;
		}
	}
`;

const CardContainer = styled(Description)`
	padding-left: 5rem;

	@media (max-width: 900px) {
		padding-left: 0;
	}
`;

export default ServicesSection;
