import home1 from "../img/home1.jpg";
import { About, Description, Hide, Image } from "../style";

//Framer motion
import { motion } from "framer-motion";
import { titleAnimation, fade, photoAnimation } from "../Animation";
import Wave from "./Wave";
import { Link } from "react-router-dom";

const AboutSection = () => {
	return (
		<About>
			<Description>
				<motion.div>
					<Hide>
						<motion.h2 variants={titleAnimation}>
							Helping <span>awesome</span> people
						</motion.h2>
					</Hide>
					<Hide>
						<motion.h2 variants={titleAnimation}>
							realise their <span>amazing</span> ideas
						</motion.h2>
					</Hide>
					<Hide>
						<motion.h2 variants={titleAnimation}>
							with a <span>personal website</span>
						</motion.h2>
					</Hide>
				</motion.div>
				<motion.p variants={fade}>
					Welkom in de digitale jungle! Een plek waar je snel kan
					verdwalen tussen allerlei technieken en mogelijkheden. Met
					al deze opties is het soms lastig om overzicht te houden en
					er achter te komen wat de beste oplossing is voor uw idee.
					Welke route door de jungle is voor uw bedrijf het beste?
				</motion.p>
				<motion.p variants={fade}>
					Silver Spirit is een kleinschalige onderneming die
					persoonlijk met u kijkt wat het beste bij u past. Hierbij
					wordt te allen tijde nauw gecommuniceerd zodat al uw wensen
					zo goed mogelijk gerealiseerd worden. Er zal altijd rekening
					gehouden worden met uw technische kennis zodat bij
					oplevering ook u het product snapt en kan onderhouden.
				</motion.p>
				<Link to="/contact">
					<motion.button variants={fade}>
						Neem vrijblijvend contact op!
					</motion.button>
				</Link>
			</Description>
			<Image>
				<motion.img
					variants={photoAnimation}
					src={home1}
					alt="home image"
				/>
			</Image>
			<Wave />
		</About>
	);
};

export default AboutSection;
