//Import Images
import cassettePlayer from "./img/casettePage.jpeg";
import portfolio from "./img/portfolio.jpeg";

export const PortFolioItems = () => {
	return [
		{
			key: 1,
			title: "Online Cassette Player",
			shortDescription:
				"De online casstte player is een website die ontwikkeld is door middel van React.JS. De website heeft 1 vaste URL en voelt eigenlijk een " +
				"een beetje aan als een echte app. Dankzij de korte laadtijden en soepele animaties werkt de app prettig voor de eindgebruiker.",
			longDescription: "",
			mainImg: cassettePlayer,
			url: "/work/cassette",
			buttonUrl: "https://silver-spirit-port-folio.firebaseapp.com/",
			buttonText: "Bekijk de player in een nieuw tabblad",
			details: [
				{
					title: "React.JS",
					description:
						"Dankzij React.JS werkt de app met 1 URL en voelt de app soepel aan",
				},
				{
					title: "SASS",
					description:
						"Voor de styling wordt gebruikt gemaakt van SASS",
				},
				{
					title: "Vele mogelijkheden",
					description:
						"Nummers kunnen worden afgespeeld, gefilterd worden obv genre & artiest en daarnaast zijn er ook vele controls zoals skip, volume" +
						" en kunnen de nummers op shuffle gezet worden of herhaal modus.",
				},
				{
					title: "Animaties",
					description:
						"De cassette draait mee met het nummer en bevat de naam & album cover van het nummer",
				},
			],
		},
		{
			title: "Mijn Portfolio",
			mainImg: portfolio,
			url: "/work/portFolio",
			buttonUrl: "/",
			buttonText: "Bekijk de pagina in een nieuw tablad",
			shortDescription:
				"De online casstte player is een website die ontwikkeld is door middel van React.JS. De website heeft 1 vaste URL en voelt eigenlijk een " +
				"een beetje aan als een echte app. Dankzij de korte laadtijden en soepele animaties werkt de app prettig voor de eindgebruiker.",
			longDescription: "",
			details: [
				{
					title: "React.JS",
					description:
						"Dankzij React.JS navigeert de site soepel en snel wanneer je naar een andere pagina navigeert.",
				},
				{
					title: "Styled Components",
					description:
						"De site is gemaakt dankzij styled components, welke zorgen voor overzicht in de code.",
				},
				{
					title: "Animaties",
					description:
						"Dankzij Framer Motion heeft de site soepele en vlotte animaties die het geheel tot leven wekken.",
				},
			],
		},
	];
};
