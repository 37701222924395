import AboutSection from "../components/AboutSection";
import ServicesSection from "../components/ServicesSection";
import FAQSection from "../components/FAQSection";

//Animation import
import { motion } from "framer-motion";
import { pageAnimation } from "../Animation";
import AboutMe from "../components/AboutMe";

const AboutUs = () => {
	return (
		<motion.div
			exit="exit"
			variants={pageAnimation}
			initial="hidden"
			animate="show"
		>
			<AboutSection />
			<ServicesSection />
			<AboutMe />
			<FAQSection />
		</motion.div>
	);
};

export default AboutUs;
