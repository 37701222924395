import styled from "styled-components";
import { About } from "../style";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../Animation";

const FAQSection = () => {
	const [element, controls] = useScroll();

	return (
		<Faq
			variants={scrollReveal}
			animate={controls}
			initial="hidden"
			ref={element}
		>
			<h2>
				Vragen of onduidelijkheden? <span>FAQ</span>
			</h2>
			<AnimateSharedLayout>
				<Toggle title="Hoe gaat een samenwerking van start?">
					<div className="answer">
						<p>
							Er zal allereerst een intake ingepland worden om uw
							wensen door te nemen. Deze intake is kosteloos en
							vrijblijvend. Na deze intake wordt een offerte of
							prijsvoorstel gemaakt, waarna bij akkoord zo snel
							mogelijk aan de slag gegaan wordt.
						</p>
						<p>
							Over het algemeen zal bij elk project een start- en
							einddatum toegekend worden zodat u precies weet wat
							u kan verwachten. Er zullen tussendoor altijd
							updates gegeven worden m.b.t. de voortgang van het
							project.
						</p>
					</div>
				</Toggle>
				<Toggle title="Wat zijn de tarieven?">
					<div className="answer">
						<p>
							Standaard uurtarief: <b>€75,- (excl.)</b>
						</p>
						<p>
							Wanneer het gaat om een kort of doorlopend
							ontwikkeltraject, zal vaak een uurtarief gerekend
							worden voor de werkzaamheden. Indien het om een
							volledig project gaat, is het mogelijk om een
							prijsafspraak te maken op basis van het gehele
							project.
						</p>
					</div>
				</Toggle>
				<Toggle title="Wat is allemaal mogelijk?">
					<div className="answer">
						<p>
							Silver Spirit beschouwt zichzelf als creatief
							webbureau en gelooft in de kracht van het internet.
							Hoewel ik bijvoorbeeld wel ervaring heb met talen
							als Python en Java, specialiseer ik mijzelf
							voornamelijk in het web.
						</p>
						<p>
							Denk hierbij aan bijvoorbeeld Javascript (React.JS,
							Jquery), PHP (Symfony, Laravel, MVC projecten), HTML
							en CSS (SASS, Styled Components). Hierbij zullen ook
							de meer standaard oplossingen overwogen worden zoals
							Wordpress voor kleinere bedrijven.
						</p>
						<p>
							Voor een back-end oplossing zal ik voornamelijk
							terugvallen op systemen die u zelf ook kunt
							gebruiken en onderhouden. Denk hierbij aan Google
							Firebase & Wordpress. Het grote voordeel hiervan is
							dat u niet onnodig veel gaat betalen voor de
							ontwikkeling een back-end systeem en alle hosting
							centraal kan staan op 1 locatie.
						</p>
					</div>
				</Toggle>
			</AnimateSharedLayout>
		</Faq>
	);
};

const Faq = styled(About)`
	display: block;

	span {
		display: block;
	}

	h2 {
		padding-bottom: 2rem;
		font-weight: lighter;
	}

	.faq-line {
		background: #cccccc;
		height: 0.2rem;
		width: 100%;
		margin: 2rem 0;
	}

	.question {
		padding: 3rem 0;
		cursor: pointer;
	}

	h4 {
		font-size: 2.5rem;
	}

	.answer {
		padding: 2rem 0;

		p {
			padding: 1rem 0;
			font-size: 1.6rem;
		}
	}
`;

export default FAQSection;
