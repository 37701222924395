import home1 from "../img/Auto.jpg";
import { About, Description, Hide, Image } from "../style";

//Framer motion
import { motion } from "framer-motion";
import {
	titleAnimation,
	fade,
	photoAnimation,
	scrollReveal,
} from "../Animation";
import Wave from "./Wave";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useScroll } from "./useScroll";

const AboutMe = () => {
	const [element, controls] = useScroll();

	return (
		<About
			variants={scrollReveal}
			animate={controls}
			initial="hidden"
			ref={element}
		>
			<AboutMeDescription>
				<motion.div>
					<Hide>
						<motion.h2 variants={titleAnimation}>
							Wie ben ik <span>persoonlijk</span>?
						</motion.h2>
					</Hide>
				</motion.div>
				<motion.p variants={fade}>
					Aangenaam kennis te maken! Mijn naam is{" "}
					<span>Jesse Tijsma</span> en ik ben al mijn hele leven
					geïnteresseerd in techniek, muziek, auto's en vooral ook
					reizen. Al vanaf kinds af aan kan ik goed omgaan met
					computers en snap ik ook hoe ze werken.
				</motion.p>
				<motion.p variants={fade}>
					Persoonlijk vind ik het daarom ook altijd erg grappig
					wanneer mensen boos worden op een computer, klagend dat ze
					niet doen wat ze willen. Daarom is <span>UX & UI</span> ook
					een ontzettend belangrijk onderdeel wanneer het aankomt op
					ontwikkelen, anders gaan mensen zelf gokken hoe een systeem
					werkt en worden bepaalde functies niet goed, of zelfs
					helemaal niet gebruikt. Mensen doen soms net alsof apparaten
					gevoel hebben... al moet ik zeggen dat we met ChatGPT & AI
					daar wel steeds meer in de buurt van komen!
				</motion.p>
				<motion.p variants={fade}>
					Ondanks mijn affiniteit met techniek, vind ik het machtig
					mooi om soms even volledig offline te gaan door bijvoorbeeld
					in mijn auto de wereld te verkennen. Het verbinden met de
					natuur, verkennen van kleine afgelegen dorpjes en er lekker
					spontaan op uit gaan vind ik geweldig.
				</motion.p>
				<Link to="/contact">
					<motion.button variants={fade}>
						Neem contact op
					</motion.button>
				</Link>
			</AboutMeDescription>
			<AboutMeImage>
				<motion.img
					variants={photoAnimation}
					src={home1}
					alt="home image"
				/>
			</AboutMeImage>
		</About>
	);
};

const AboutMeDescription = styled(Description)`
	flex: 1 50%;

	p {
		span {
			color: white;
		}
	}
`;

const AboutMeImage = styled(Image)`
	flex: 1 50%;
`;

export default AboutMe;
