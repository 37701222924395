//Animation import
import { motion } from "framer-motion";
import { pageAnimation, titleAnimation } from "../Animation";
import styled from "styled-components";

const ContactUs = () => {
	return (
		<ContactPage
			exit="exit"
			variants={pageAnimation}
			initial="hidden"
			animate="show"
		>
			<div className="content">
				<ContactStyle>
					<Title>
						<Hide>
							<motion.h2 variants={titleAnimation}>
								Contacteer ons!
							</motion.h2>
						</Hide>
					</Title>
					<div>
						<Hide>
							<Social variants={titleAnimation}>
								<Circle />
								<h2>
									<a href="mailto:jesse.tijsma.zakelijk@gmail.com">
										Klik hier
									</a>{" "}
									om ons een email te sturen{" "}
								</h2>
							</Social>
						</Hide>
						<Hide>
							<Social variants={titleAnimation}>
								<Circle />
								<h2>
									Liever zelf het mailadres invullen?{" "}
									<a>Jesse.Tijsma.zakelijk@gmail.com</a>
								</h2>
							</Social>
						</Hide>
						<Hide>
							<Social variants={titleAnimation}>
								<Circle />
								<h2>
									Liever persoonlijk contact via WhatsApp?{" "}
									<a href="https://wa.me/31683513609">
										Klik hier!
									</a>
								</h2>
							</Social>
						</Hide>
					</div>
				</ContactStyle>
				<Waves>
					<svg
						className="waves"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 24 150 28"
						preserveAspectRatio="none"
						shapeRendering="auto"
					>
						<defs>
							<path
								id="gentle-wave"
								d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
							/>
						</defs>
						<g className="parallax">
							<use
								xlinkHref="#gentle-wave"
								x="48"
								y="0"
								fill="rgba(204,204,204,0.7)"
							/>
							<use
								xlinkHref="#gentle-wave"
								x="48"
								y="3"
								fill="rgba(204,204,204,0.5)"
							/>
							<use
								xlinkHref="#gentle-wave"
								x="48"
								y="5"
								fill="rgba(204,204,204,0.3)"
							/>
							<use
								xlinkHref="#gentle-wave"
								x="48"
								y="7"
								fill="#B6B6BB"
							/>
						</g>
					</svg>
				</Waves>
			</div>
			<div className="footer">
				<p>© Silver Spirit - 2023</p>
			</div>
		</ContactPage>
	);
};

const ContactPage = styled(motion.div)`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0;
	padding: 0;

	.content {
		position: relative;
		margin-bottom: -7px; /*Fix for gap*/
	}

	.footer {
		position: relative;
		height: 10vh;
		background-color: #b6b6bb;

		p {
			text-align: center;
			color: #1b1b1b;
			font-weight: 600;
		}

		@media (max-width: 1200px) {
			height: 20vh;
		}

		@media (max-width: 550px) {
			height: 30vh;
		}
	}
`;

const ContactStyle = styled(motion.div)`
	padding: 5rem 10rem;
	color: rgba(255, 255, 255, 0.9);
	min-height: 60vh;
	@media (max-width: 1500px) {
		padding: 2rem;
		font-size: 1rem;
	}
`;
const Title = styled.div`
	margin-bottom: 4rem;
	color: rgba(255, 255, 255, 0.9);
	@media (max-width: 1500px) {
		margin-top: 5rem;
	}
`;
const Hide = styled.div`
	overflow: hidden;
`;
const Circle = styled.div`
	border-top: 5px solid white;
	max-width: 25px;
`;
const Social = styled(motion.div)`
	display: flex;
	align-items: center;

	div {
		width: 10%;
	}

	h2 {
		width: 90%;

		font-size: 2rem;
		padding: 2rem;
		overflow-wrap: break-word;

		a {
			text-decoration: none;
			color: white;
			font-size: 2rem;
			font-weight: 900;

			@media (max-width: 900px) {
				text-decoration: underline;
			}
		}

		a:hover {
			text-decoration: underline;
		}
	}
`;

const Waves = styled.div`
	position: relative;
	width: 100vw;
	height: 100%;

	.parallax > use {
		animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
	}

	.parallax > use:nth-child(1) {
		animation-delay: -2s;
		animation-duration: 7s;
	}

	.parallax > use:nth-child(2) {
		animation-delay: -3s;
		animation-duration: 10s;
	}

	.parallax > use:nth-child(3) {
		animation-delay: -4s;
		animation-duration: 13s;
	}

	.parallax > use:nth-child(4) {
		animation-delay: -5s;
		animation-duration: 20s;
	}

	@keyframes move-forever {
		0% {
			transform: translate3d(-90px, 0, 0);
		}
		100% {
			transform: translate3d(85px, 0, 0);
		}
	}
`;

export default ContactUs;
