import AboutUs from "./pages/AboutUs";
import OurWork from "./pages/OurWork";
import WorkDetail from "./pages/WorkDetail";
import ContactUs from "./pages/ContactUs";

import GlobalStyle from "./components/GlobalStyle";
import Nav from "./components/Nav";

//Router
import { Route, Routes, useLocation } from "react-router-dom";

//Animation
import { AnimatePresence } from "framer-motion";
import ScrollTop from "./components/ScrollTop";

const App = () => {
	const location = useLocation();

	return (
		<div className="App">
			<GlobalStyle />
			<Nav />
			<AnimatePresence mode="wait">
				<Routes location={location} key={location.key}>
					<Route path="/" exact element={<AboutUs />} />
					<Route path="/work" exact element={<OurWork />} />
					<Route path="/contact" exact element={<ContactUs />} />
					<Route path="/work/:id" exact element={<WorkDetail />} />
				</Routes>
				<ScrollTop />
			</AnimatePresence>
		</div>
	);
};

export default App;
