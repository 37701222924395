import styled from "styled-components";
import { useParams } from "react-router-dom";

import { PortFolioItems } from "../PortFolioItems";
import React, { useEffect, useState } from "react";

//Animation import
import { motion } from "framer-motion";
import { fade, pageAnimation } from "../Animation";

const WorkDetail = () => {
	const history = useParams();
	const [portFolioItems] = useState(PortFolioItems);
	const [portfolioItem, setPortFolioItem] = useState(null);

	useEffect(() => {
		const currentPortFolioItem = portFolioItems.filter(
			(stateMovie) => stateMovie.url === "/work/" + history.id
		);

		setPortFolioItem(currentPortFolioItem[0]);
	}, [portFolioItems, history]);

	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="show"
			exit="exit"
		>
			{portfolioItem && (
				<Details>
					<HeadLine>
						<div>
							<h2>{portfolioItem.title}</h2>
						</div>
						<p>{portfolioItem.shortDescription}</p>
						<a
							target="_blank"
							href={portfolioItem.buttonUrl}
							rel="noreferrer"
						>
							<motion.button variants={fade}>
								{portfolioItem.buttonText}
							</motion.button>
						</a>

						<img
							src={portfolioItem.mainImg}
							alt={portfolioItem.title}
						/>
					</HeadLine>
					<Awards>
						<div>
							<h2>Highlights & gebruikte technieken</h2>
						</div>
						{portfolioItem.details.map((detail) => (
							<Award
								title={detail.title}
								description={detail.description}
								key={detail.title}
							/>
						))}
					</Awards>
					{portfolioItem?.secondaryImg && (
						<ImageDisplay>
							<img
								src={portfolioItem.secondaryImg}
								alt={portfolioItem.title}
							/>
						</ImageDisplay>
					)}
				</Details>
			)}
		</motion.div>
	);
};

const Details = styled.div`
	color: white;
`;

const HeadLine = styled.div`
	min-height: 90vh;
	padding-top: 10vh;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;

	button {
		margin: 2rem;
	}

	h2 {
		display: flex;
		width: 100%;
		text-align: center;
	}

	p {
		padding: 2rem 10rem;
	}

	@media (max-width: 900px) {
		text-align: center;

		h2 {
			font-size: 5rem;
		}

		p {
			padding: 2rem 3rem;
			font-size: 1.8rem;
		}

		button {
			font-size: 1.8rem;
		}
	}

	img {
		margin: 3rem 0;
		width: 80%;
		height: auto;
		object-fit: cover;
	}
`;

const Awards = styled.div`
	min-height: 80vh;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 5rem 10rem;
	width: 100%;

	@media (max-width: 900px) {
		display: block;
		padding: 2rem;
	}

	div:first-child {
		display: flex;
		width: 100%;
		justify-content: center;
		h2 {
			text-align: center;
		}
	}

	div {
		margin: auto;
	}
`;

const AwardStyle = styled.div`
	max-width: 45%;
	padding: 5rem;

	h3 {
		font-size: 2rem;
	}

	.line {
		width: 100%%;
		background-color: #23d997;
		height: 0.5rem;
		margin: 1rem 0;
	}

	p {
		padding: 2rem 0;
	}

	@media (max-width: 900px) {
		max-width: 100%;
		h3 {
			font-size: 3rem;
		}

		p {
			font-size: 1.8rem;
		}
	}
`;

const ImageDisplay = styled.div`
	min-height: 50vh;

	img {
		width: 100%;
		height: 100vh;
	}
`;

const Award = ({ title, description }) => {
	return (
		<AwardStyle>
			<h3>{title}</h3>
			<div className="line" />
			<p>{description}</p>
		</AwardStyle>
	);
};

export default WorkDetail;
