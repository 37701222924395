import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const Nav = () => {
	const { pathname } = useLocation();

	return (
		<StyledNav>
			<h1>
				<Link className="logo" to="/">
					Silver Spirit
				</Link>{" "}
			</h1>
			<ul>
				<li>
					<Link className="link" to="/">
						1. Over ons
					</Link>
					<Line
						transition={{ duration: 0.75 }}
						initial={{ width: "0%" }}
						animate={{
							width: pathname === "/" ? "50%" : "0%",
						}}
					/>
				</li>
				<li>
					<Link className="link" to="work">
						2. Onze projecten
					</Link>
					<Line
						transition={{ duration: 0.75 }}
						initial={{ width: "0%" }}
						animate={{
							width: pathname.includes("/work") ? "50%" : "0%",
						}}
					/>
				</li>
				<li>
					<Link className="link" to="contact">
						3. Contact
					</Link>
					<Line
						transition={{ duration: 0.75 }}
						initial={{ width: "0%" }}
						animate={{
							width: pathname === "/contact" ? "50%" : "0%",
						}}
					/>
				</li>
			</ul>
		</StyledNav>
	);
};

const StyledNav = styled.nav`
	display: flex;
	min-height: 10vh;
	margin: auto;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 10rem;
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: #282828;

	ul {
		display: flex;
		list-style: none;
	}

	.logo {
		font-family: "Lobster", cursive;
		font-size: 1.5rem;
		text-decoration: none;
		font-weight: lighter;
		color: white;
	}

	li {
		padding-left: 10rem;
		position: relative;

		.link {
			color: white;
			text-decoration: none;
		}
	}

	@media (max-width: 900px) {
		flex-direction: column;
		padding: 2rem 0;

		li {
			padding-left: 0;
		}

		ul {
			padding-bottom: 2rem;
			justify-content: space-around;
			width: 100%;
		}
	}
`;

const Line = styled(motion.div)`
	height: 0.3rem;
	background: #23d997;
	width: 0;
	position: absolute;
	bottom: -80%;
	left: 55%;
	@media (max-width: 900px) {
		left: 0;
	}
`;

export default Nav;
