import styled from "styled-components";
import { motion } from "framer-motion";

export const About = styled(motion.div)`
	min-height: 90vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5rem 10rem;
	color: white;

	@media (max-width: 900px) {
		display: block;
		padding: 5rem 2rem 2rem;
		text-align: center;
		p {
			font-size: 1.6rem;
		}

		button {
			font-size: 1.75rem;
		}
	}
`;

export const Description = styled.div`
	flex: 1 60%;
	padding-right: 5rem;

	h2 {
		font-weight: lighter;

		@media (max-width: 400px) {
			font-size: 2.5rem;
			margin: 1rem 0;
		}

		@media (max-width: 450px) {
			font-size: 3rem;
		}
	}

	@media (max-width: 900px) {
		padding-right: 0;
		padding-bottom: 2rem;

		button {
			margin: 2rem 0 5rem;
		}
	}

	p {
		padding: 1em 0 1em 0;
		text-shadow: -1px -1px 0 #1b1b1b, 1px -1px 0 #1b1b1b, -1px 1px 0 #1b1b1b,
			1px 1px 0 #1b1b1b;
	}
`;

export const Image = styled.div`
	flex: 1 40%;

	img {
		width: 100%;
		height: 80vh;
		object-fit: cover;
	}
`;

export const Hide = styled.div`
	overflow: hidden;
`;
